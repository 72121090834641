import React from "react"
// @ts-ignore
import ReactStoreBadges from "react-store-badges"
import { Card, CardContent } from "@material-ui/core"
import { Languages, DONOTSKIP_URLS } from "@arnaud-cortisse/donotskip-core"

const languageIdToStoreLocal = {
  [Languages.Ids.FR]: "fr-fr",
  [Languages.Ids.EN]: "en-en",
}

interface AppStoreBadges {
  languageId: Languages.Ids
}
const AppStoreBadges: React.FC<AppStoreBadges> = ({ languageId }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <ReactStoreBadges
        platform={"ios"}
        url={DONOTSKIP_URLS.DONOTSKIP_APP_APP_STORE}
        locale={languageIdToStoreLocal[languageId]}
      />
      <ReactStoreBadges
        platform={"android"}
        url={DONOTSKIP_URLS.DONOTSKIP_APP_PLAY_STORE}
        locale={languageIdToStoreLocal[languageId]}
      />
    </div>
  )
}

export default AppStoreBadges

import React from "react"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Slider, { Settings } from "react-slick"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const SliderSettings: Settings = {
  dots: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 4000,
  infinite: true,
  slidesToShow: 1,
  draggable: true,
  pauseOnHover: false,
}

const AppImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "app" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const elements = (data.allFile.nodes as any[])
    .map(node => ({ ...node, index: parseInt(node.name) }))
    .sort()

  return (
    <Slider {...SliderSettings}>
      {elements.map((element, index) => {
        const { childImageSharp } = element
        return (
          <div key={index}>
            <Img fluid={childImageSharp.fluid} alt={`App slide ${index}`} />
          </div>
        )
      })}
    </Slider>
  )
}

export default AppImages

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroApp from "../components/hero-app"
import MyAppBar from "../components/my-app-bar"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../styles/theme"
import { Container } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import headerImage from "../images/header.png"

const MobileAppPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout>
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100%",
          width: "100%",
          opacity: 0.5,
        }}
      />
      <MyAppBar siteTitle={"DoNotSkip"} />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container maxWidth="md" style={{ flex: 1 }}>
          <HeroApp siteTitle={siteTitle} />
        </Container>
        <Footer siteTitle={siteTitle} />
      </div>
    </Layout>
  )
}

export default MobileAppPage

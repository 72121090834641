import React from "react"

import AppImages from "./app-images"
import { useTranslation, usePageContext } from "@3nvi/gatsby-theme-intl"
import { I18N_NAMESPACES } from "../i18next/i18n"
import { Typography } from "@material-ui/core"
import AppStoreBadges from "./AppStoreBadges.component"
import { Languages } from "@arnaud-cortisse/donotskip-core"

interface Hero {
  siteTitle: string
}
const HeroApp: React.FC<Hero> = ({ siteTitle }) => {
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON)
  const { lang } = usePageContext()

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h3" style={{ textAlign: "center" }}>
        {siteTitle}
      </Typography>
      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        {tCommon("Never skip another workout")}
      </Typography>
      <div style={{ marginBottom: 60, width: `250px`, position: "relative" }}>
        <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}>
          <AppImages />
        </div>
      </div>
      <AppStoreBadges languageId={lang as Languages.Ids} />
    </div>
  )
}

export default HeroApp
